import React from 'react';

const ContentfulLinks = (node) => {
  const uri = node.data.uri;
  const isWebsiteUrl =
    (uri.includes('quashed.co.nz') || uri.includes('#')) &&
    !uri.includes('/documents');
  return (
    <a
      href={node.data.uri}
      target={`${isWebsiteUrl ? '_self' : '_blank'}`}
      rel={`${isWebsiteUrl ? '' : 'noopener noreferrer'}`}
      onClick={(e) => e.stopPropagation()}
    >
      {node.content[0].value}
    </a>
  );
};

export default ContentfulLinks;
