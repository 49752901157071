export const homeFaqs = [
  {
    order: 0,
    anchor: null,
    title: 'Do we need to compare insurance in New Zealand? ',
    content:
      "Like most things we buy, comparisons help to better inform us of what is available out there and whether we're getting a good deal. The cost of insurance can vary widely across insurance companies. This means potentially huge savings in the hundreds of dollars, sometimes even thousands.",
  },
  {
    order: 1,
    anchor: null,
    title: 'How can I compare insurance in New Zealand?',
    content:
      'Before Quashed, comparing insurance is difficult and slow. Typing your information into different insurer websites and only getting a price comparison, not knowing how the policy is different. Some Kiwis will use insurance brokers or advisers. However, there aren’t enough advisers or brokers to go around, and not everyone wants to meet or talk to someone. Quashed is the new way to compare insurance. It is all online, fast and transparent. In less than three minutes, you can compare insurance with Quashed.',
  },
  {
    order: 2,
    anchor: null,
    title: 'What insurance can Quashed compare?',
    content:
      'Quashed can help to compare car insurance, house insurance, contents insurance, landlord insurance, motorcycle insurance and even life insurance all online today.',
  },
  {
    order: 3,
    anchor: null,
    title: 'Which insurance company is the cheapest or best in New Zealand?',
    content:
      "There is no insurance company that is the best or cheapest for everyone. The cost of insurance depends on several factors such as your claim history, and the insurer's desire to take on your 'risk'. The best insurance policy also depends on what benefits you care about. This is why comparing insurance is so important.",
  },
  {
    order: 4,
    anchor: null,
    title:
      'How is Quashed different from an insurance broker or insurance adviser?',
    content: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Most insurance brokers and advisers provide a face-to-face or phone call service. With Quashed, you compare insurance quotes and policy benefits in real-time online at any time.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Insurance advisers or insurance brokers typically compare policies that you cannot buy directly from brands such as Ando, NZI, Vero for your car insurance, house insurance, contents insurance. Quashed can compare brands that include AMP, AA, Tower, Cove, Initio, and more.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Quashed also offers a free-to-use policy tracker where you can store and see a summary of all your insurance information in one dashboard. Yes, even your pet insurance, travel insurance, business insurance and more. You can see how much you’re spending on insurance, get alerts as your policy is renewing, and easily compare and shop your insurance, and more.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
  },
  {
    order: 5,
    anchor: null,
    title: 'Is Quashed insurance comparison free to use?',
    content:
      'Yes, our insurance comparison feature called Market Scan is completely free-to-use. To learn how we make money, refer to our FAQ page. And no, we do not sell your information.',
  },
  {
    order: 6,
    anchor: null,
    title:
      'Does it cost more to buy insurance via Quashed compared to buying directly from the insurer?',
    content:
      'No, we do not add on any fees or charges. You’ll see the same price if you went directly to the insurance company. You will of course have to enter the same information used on Quashed.',
  },
];
